import React from 'react';

const getMarkup = (parsedTag: string|number): string => `
            <script async src="https://www.googletagmanager.com/gtag/js?id=${parsedTag}"></script>
            <script>
               window.dataLayer = window.dataLayer || [];
               function gtag(){dataLayer.push(arguments);} 
               gtag('js', new Date()); gtag('config', '${parsedTag}');
               gtag('event', 'conversion', {
                  'send_to': '${parsedTag}',
                  'value': 1.0,
                  'currency': 'USD',
               });
            </script>
        `;

const MicrositeGtagProvider = ({ children, data }: { children: React.ReactNode, data: any }): JSX.Element => {
    const adwordsTag = data?.franchise?.googleAdsTag;
    const elRef = React.useRef<HTMLDivElement|null>(null);

    React.useEffect(() => {
        if (adwordsTag && elRef.current) {
            if ('innerHTML' in elRef.current) {
                const range = document.createRange();
                range.selectNode(elRef.current);
                const documentFragment = range.createContextualFragment(getMarkup(adwordsTag));
                elRef.current.innerHTML = '';
                elRef.current.append(documentFragment);
            }
        }
    }, [adwordsTag]);

    if (adwordsTag) {
        return (
            <>
                <span ref={elRef} dangerouslySetInnerHTML={{ __html: getMarkup(adwordsTag) }} />
                {children}
            </>
        );
    }

    return (
        <>
            {children}
        </>
    );
};

export default MicrositeGtagProvider;
