// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { generateFranchiseLdJsonMarkup } from '../../components/utils/franchise-ld-json-markup';
import { useDniProvider } from '../dni-provider';
import useUserIdentifier from '../../hooks/use-user-identifier';
import useLocalStorageState from '../../hooks/use-local-storage-state';
import { useTemplateProvider } from '../template-provider';
import { PageTemplate } from '../../utils/match-page-template';
import { FranchiseData } from '../../sourcing/source-nodes/sources/franchises';
import { WikipediaGeoLocationData } from '../../sourcing/source-nodes/sources/geo-wikipedias';

const LOCAL_STORAGE_IS_MICROSITE_KEY = 'is_microsite';
const LOCAL_STORAGE_USER_ID_KEY = 'sp_visitor_id';

// TODO: Finish typing this

type UniqueContentItem = {
    approvedDate: string,
    content: string,
    rawContent: string,
    pageName: string
};

interface MicrositeContextReturnable {
    isMicrosite: boolean,
    isLocalCityPage: boolean,

    isHyperLocalTemplate: boolean
    isMetroTemplate: boolean
    isMetroStateTemplate: boolean

    franchise: FranchiseData | null,
    name: string|null
    permalink: string | null,

    menu: Queries.Cms_franchise_primary_nav | null,
    footer: Queries.Cms_franchise_footer_nav | null,
    allowedInternalLinks: (string | null)[] | null,
    bannedInternalLinks: (string | null)[] | null,
    filteredServices: unknown,
    targetCity: {
        city?: string | null
        state?: string | null
        content?: string | null
    } | null

    uniqueContent: Record<string, UniqueContentItem>
}

const MicrositeContext = React.createContext(null as unknown as MicrositeContextReturnable);

interface MicrositeProps {
    children: React.ReactNode
    data: {
        targetCity: Queries.TargetCity
        franchise: Queries.Franchise
        cmsMetroPage: Queries.Cms_metro_page
        allFranchiseUniqueContent: Pick<Queries.FranchiseUniqueContentConnection, 'nodes'>
    }
}

const addToDataLayer = (eventName: string, key: string, value: any): void => {
    // value must not be null or undefined but maybe be a boolean and be false
    if (value === null || value === undefined) {
        return;
    }
    // dataLAyer should already be initialized as both gatsby-browser and GTM conditionally initializes it
    if ('dataLayer' in window && Array.isArray(window.dataLayer)) {
        // gets the most recent item of the same event type
        const lastOfEventType = window.dataLayer.findLast(item => item.event === eventName);

        // Update only if either (a) we don't already have that event, or (b) we do, but its value for 'key' is different from 'value'
        if (!lastOfEventType || lastOfEventType[key] !== value) {
            window.dataLayer.push({
                event: eventName,
                [key]: value,

            });
        }
    }
};

type ExtendedFranchiseData = FranchiseData & { wikipediaBasedGeographicAreas: WikipediaGeoLocationData[] };

const MicrositeProvider = ({ children, data = {} }: MicrositeProps): JSX.Element => {
    const location = useLocation();
    const { numberMap } = useDniProvider();

    const franchise = React.useMemo(() => {
        if (data?.franchise || data?.cmsMetroPage?.franchises?.[0]) {
            return { ...(data?.franchise || data?.cmsMetroPage?.franchises?.[0]) } as ExtendedFranchiseData;
        }
        return null;
    }, [data?.cmsMetroPage?.franchises, data?.franchise]);

    const {
        name,
        address,
        allowedInternalLinks,
        bannedInternalLinks,
        filteredServices,
        menu,
        footer,
        mainPhone,
    } = franchise || {};

    const legacySiteEnabled = data?.franchise?.legacySiteEnabled;
    const uniqueContent: Record<string, UniqueContentItem> = (() => {
        if (!legacySiteEnabled) {
            return (data?.allFranchiseUniqueContent?.nodes || []).reduce((acc, val) => {
                if (val && val.pageName && val.content && val.rawContent) {
                    if (acc[val.pageName]) {
                        // If new approved date is greater than existing item - returns +1 or -1
                        if ((val.approvedDate).localeCompare(acc[val.pageName].approvedDate) === 1) {
                            acc[val.pageName] = val as unknown as UniqueContentItem;
                        }
                    } else {
                        acc[val.pageName] = val as unknown as UniqueContentItem;
                    }
                }
                return acc;
            }, {} as Record<string, UniqueContentItem>);
        }
        return {};
    })();

    const { template: matchedTemplate } = useTemplateProvider();

    if (mainPhone && 'mainPhone' in franchise && numberMap && numberMap[(mainPhone || '')]) {
        franchise.mainPhone = numberMap[franchise.mainPhone];
    }

    const isMicrosite = matchedTemplate === PageTemplate.MicrositePage || matchedTemplate === PageTemplate.MicrositeService;
    const isHyperLocalTemplate = matchedTemplate === PageTemplate.HyperLocal;
    const isMetroTemplate = matchedTemplate === PageTemplate.Metro;
    const isMetroStateTemplate = matchedTemplate === PageTemplate.MetroState;

    const {
        data: isMicrositeInLocalStorage,
        setData: setIsMicrositeInLocalStorage,
        getData: getSavedIsMicrositeIdBeforeFinishedLoading,
    } = useLocalStorageState<boolean>(
        LOCAL_STORAGE_IS_MICROSITE_KEY,
        async () => isMicrosite,
        [],
        null,
        () => true,
    );

    const isLocalCityPage = !!data.targetCity;
    const permalink = franchise?.permalink || '';

    const targetCity = (() => {
        const source = data.targetCity
        || data.franchise?.serviceCities?.[0];

        return {
            city: source?.city || franchise?.address?.city,
            state: source?.state || franchise?.address?.state,
            content: source?.content || '',
        };
    })();

    const providerValue = React.useMemo(() => {
        const fD: MicrositeContextReturnable = {
            name,
            allowedInternalLinks,
            bannedInternalLinks,
            filteredServices,
            footer,
            franchise,
            isHyperLocalTemplate,
            isLocalCityPage,
            isMetroStateTemplate,
            isMetroTemplate,
            isMicrosite,
            menu,
            permalink,
            targetCity,
            uniqueContent,
        };
        return fD;
    }, [
        name,
        franchise,
        permalink,
        menu,
        footer,
        allowedInternalLinks,
        bannedInternalLinks,
        filteredServices,
        isMicrosite,
        isLocalCityPage,
        targetCity,
        isHyperLocalTemplate,
        isMetroTemplate,
        isMetroStateTemplate,
        uniqueContent,
    ]);

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (process.env.NODE_ENV === 'development') {
            console.log('Franchise ID', franchise?.franchiseNumber);
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.fID = () => console.log('Franchise ID', franchise?.franchiseNumber);
        }
        // Ignore the ESLint, we only want to log this once for devs
    }, [franchise]);

    const userId = useUserIdentifier();
    const {
        data: userIdInLocalStorage,
        setData: setUserIdInLocalStorage,
        getData: getSavedUserIdBeforeFinishedLoading,
    } = useLocalStorageState<number | null>(
        LOCAL_STORAGE_USER_ID_KEY,
        async () => userId,
        [],
        null,
        () => true,
    );

    React.useEffect(() => {
        if (isMicrosite && userId && franchise?.franchiseNumber) {
            fetch(`/api/microsite?id=${userId}&franchiseNumber=${franchise.franchiseNumber}`)
                .then(r => r.text())
                .catch(() => {});
        }

        // updates isMicrosite in localstorage if needed
        const isMicrositeFromLocalStorage = isMicrositeInLocalStorage || getSavedIsMicrositeIdBeforeFinishedLoading();
        if (isMicrositeFromLocalStorage !== isMicrosite) {
            setIsMicrositeInLocalStorage(isMicrosite);
        }

        // updates userId in localstorage if needed
        const userIdFromLocalstorage = userIdInLocalStorage || getSavedUserIdBeforeFinishedLoading();
        if (userId && userId !== userIdFromLocalstorage) {
            setUserIdInLocalStorage(userId);
        }

        // adds franchiseNumber and userId to the dataLayer
        addToDataLayer('SiteVisitorId', 'SiteVisitorId', userId);
        addToDataLayer('SiteFranchiseNumber', 'FranchiseNumber', franchise?.franchiseNumber || 3000);
    }, [userId, isMicrosite, franchise?.franchiseNumber, isMicrositeInLocalStorage, getSavedIsMicrositeIdBeforeFinishedLoading, userIdInLocalStorage, getSavedUserIdBeforeFinishedLoading, setIsMicrositeInLocalStorage, setUserIdInLocalStorage]);

    const heroImage = {
        filename: 'servpro_vehicle.jpg',
        title: 'servpro_vehicle.jpg',
        url: 'https://images.contentstack.io/v3/assets/blt0a0cb058815d4d96/blt9af1c0e2eaba012a/servpro_vehicle.jpeg?width=1200&auto=webp',
    };

    return (
        <MicrositeContext.Provider value={providerValue}>
            <Helmet>
                <script type="application/ld+json">
                    {address
                        && franchise
                        && generateFranchiseLdJsonMarkup({
                            name,
                            image: heroImage.url,
                            streetAddress: `${address.address}${
                                address.addressExt ? ` ${address.addressExt}` : ''
                            }`,
                            areaServed: franchise.wikipediaBasedGeographicAreas,
                            addressLocality: address.city,
                            addressRegion: address.state,
                            postalCode: address.postalCode,
                            url: `${process.env.URL || 'https://www.servpro.com'}${location.pathname}`,
                            telephone: mainPhone,
                            servicesProvided: franchise.servicesProvided,
                            ratingValue: franchise.averageRating,
                            ratingCount: franchise.reviewCount,
                            socialMedia: franchise.socialMedia,
                        })}
                </script>
            </Helmet>
            {children}
        </MicrositeContext.Provider>
    );
};

const useMicrosite = (): MicrositeContextReturnable => {
    const ctx = React.useContext(MicrositeContext);
    if (!ctx) {
        throw new Error('useMicrosite used outside of context');
    }
    return ctx;
};

// TODO: Fix these exports, MicrositeProvider should be default, don't export MicrositeContext

export { MicrositeContext, MicrositeProvider, useMicrosite };

export const query = graphql`
    fragment FranchiseMicrositeData on Franchise {
        wikipediaBasedGeographicAreas
        legacySiteEnabled
        tempOutOfService
        googleAdsTag
        activeMarkets {
            city
            state
            priority
            link
        }
        areaServedLocationData {
            city
            state
            latitude
            longitude
        }
        relevantAnnouncements {
            uid
            title
            copy
            enable
            call_to_action {
                title
                href
            }
        }
        crewPhotos {
            url
            caption
            rawCaption
            name
        }
        bannedInternalLinks
        allowedInternalLinks
        averageRating
        reviewCount
        permalink
        franchiseNumber
        profileExcerpt
        filteredServices {
            serviceType {
                title
                sort
                franchise_service_mapping
                link {
                    href
                    title
                }
                icon {
                    title
                    url
                }
                publish_details {
                    locale
                }
            }
            franchiseLink
        }
        footer {
            links {
                title
                url
                class_name
                links {
                    title
                    url
                    class_name
                }
            }
        }
        menu {
            nav_items {
                nav_item_link {
                    href
                    title
                }
                columns {
                    group {
                        category_link {
                            href
                            title
                        }
                        icon {
                            title
                            url
                        }
                        icon_inverse {
                            title
                            url
                        }
                        subgroup {
                            title
                            subgroup_link {
                                href
                                title
                            }
                            links {
                                href
                                title
                            }
                        }
                    }
                }
            }
        }

        socialMedia {
            facebook
            twitter
            instagram
            linkedIn
        }
        paymentMethods {
            americanExpress
            discover
            masterCard
            visa
        }
        profileExcerpt
        aboutUs {
            useCareerPlug
            careerPlugUrl
            ownerPicture
            displayOwners
            owners
            affiliations {
                description
            }
            awards {
                description
            }
            certifications {
                description
            }
        }
        licenses {
            generalContractorLicense
            residentialContractorLicense
            commercialContractorLicense
            plumbingContractorLicense
            electricalContractorLicense
            cslbContractorLicense
            moldRemediationContractorLicense
            biohazardContractorLicense
        }
        snapaStatus
        rapaStatus
        capaStatus
        averageRating
        reviewCount
        googleReviewLink
        servicesProvided {
            serviceLine
        }
        name
        address {
            latitude
            longitude
            city
            countryCode
            address
            addressExt
            postalCode
            state
        }
        googlePlaceId
        mainPhone
        addressHidden
    }
`;
