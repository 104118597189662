import React from 'react';
import { useQuery } from 'react-query';

const isWithinTime = (date: number, hoursBeforeExpiry: number): boolean => {
    const inMs = hoursBeforeExpiry * 60 * 60 * 1000;
    const timeAgo = Date.now() - inMs;
    return date > timeAgo;
};

const getItem = <T = never>(key: string, expiryInHours: ((props: T) => number | null) | number | null): T | undefined => {
    try {
        const data = localStorage.getItem(key);
        if (data) {
            const parsedData = JSON.parse(data);
            // Throw out anything without an item and timestamp
            if ('item' in parsedData && 'timestamp' in parsedData) {
                if (!expiryInHours) {
                    return parsedData.item;
                }
                // throw out anything without a value for timestamp
                if (parsedData.timestamp) {
                    // timestamp can be a number or 'true' - with 'true' meaning -never- expires
                    if (typeof parsedData.timestamp === 'boolean') {
                        return parsedData.item;
                    }

                    if (typeof parsedData.timestamp === 'number') {
                        // Expiry in hours can be null, OR a number, OR a function that returns either a number or null
                        if (typeof expiryInHours === 'function') {
                            const expiry = expiryInHours(parsedData.item);
                            if (typeof expiry === 'number' && isWithinTime(parsedData.timestamp, expiry)) {
                                return parsedData.item;
                            }
                        } else if (isWithinTime(parsedData.timestamp, expiryInHours)) {
                            return parsedData.item;
                        }
                    }
                }
            }
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
};

const setItem = <T = never>(key: string, item: T): void => {
    try {
        localStorage.setItem(key, JSON.stringify({
            item,
            timestamp: Date.now(),
        }));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

interface UseLocalStorageStateReturnable<T> {
    loading: boolean
    data: T|null,
    setData: <T>(props: T) => void,
    getData: () => T|null
}

const useLocalStorageState = <T = never>(
    key: string,
    initialState: () => Promise<T>,
    deps: React.DependencyList = [],
    expiryInHours: ((props: T) => number)|number|null = null,
    shouldResetClock: ((props: T) => boolean)|boolean|null = null
): UseLocalStorageStateReturnable<T> => {
    const { isLoading, data: foundData, refetch } = useQuery({
        queryKey: ['local-storage-state', key, ...deps],
        queryFn: async () => {


            const data = getItem(key, expiryInHours);
            if (data) {
                if (shouldResetClock) {
                    // this doers not update the object but only the timestamp
                    // e.g.: if the user selected a franchise, and timestamp has no expired (i.e.: within 72h),
                    // we'll reset the clock and give him another 72 hrs such that, as long as he's active on the site
                    // we will not removed his selected franchise.
                    if (typeof shouldResetClock === 'function' && shouldResetClock(data)) {
                        setItem(key, data);
                    }
                    if (typeof shouldResetClock === 'boolean' && shouldResetClock) {
                        setItem(key, data);
                    }
                }
                return data;
            }
            if (initialState) {
                const gotData = await initialState();
                setItem(key, gotData);
                return gotData;
            }
            return null;
        },
    });

    const performGet = React.useCallback((): void => {
        refetch({
            queryKey: ['local-storage-state', key, ...deps],
        });
    }, [deps, key, refetch]);

    const performSet = (item: T): void => {
        setItem(key, item);
        performGet();
    };

    // commented it as it was endlessly updating local storage
    // React.useEffect(() => {
    //     // engage in this only if required... (based on a prop?)
    //     // window.addEventListener('storage', performGet);
    //     // return () => {
    //     //     window.removeEventListener('storage', performGet);
    //     // };
    // }, [key, performGet]);

    if (!key) {
        throw new Error('useLocalStorageState: a key must be set');
    }

    return {
        data: foundData || null,
        loading: isLoading,
        setData: performSet,
        getData: () => {
            const data = getItem(key, expiryInHours);
           // a boolean of value 'false' would be false but it is a legitimate value
            if (data || typeof data === 'boolean') {
                return data;
            }
            return null;
        },
    };
};

export default useLocalStorageState;
