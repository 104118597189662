import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withPrefix } from 'gatsby-link';
import getCountryISO3 from 'country-iso-2-to-3';
import useLocalStorageState from '../../hooks/use-local-storage-state';
import useNearbyFranchises from '../../hooks/use-nearby-franchises';
import type {
    UseNearbyFranchiseMultipleItems,
    UseNearbyFranchiseSingleItem,
} from '../../hooks/use-nearby-franchises';
import { PHONE_NUMBER } from '../../constants/constants';
import { SearchInputAddressComponents } from '../../components/franchise/franchise-search-input';
import { FranchiseData } from '../../sourcing/source-nodes/sources/franchises';
import { Announcement } from '../../utils/filter-announcements';

const LOCAL_GEO_KEY = 'serv_geo_v4';

enum Provider {
    NETLIFY = 'netlify',
    IPSTACK = 'ipStack',
    NAVIGATOR = 'navigator',
    FRANCHISE = 'franchise',
}

const franchiseToGeo = (
    franchise: FranchiseData,
): UserLocationData | null => {
    if (!franchise) { return null; }

    if (
        franchise?.address?.city
        && typeof franchise?.areaServedLocationData?.latitude === 'number'
        && typeof franchise?.areaServedLocationData?.longitude === 'number'
        && franchise?.address?.state
        && franchise?.address?.countryCode
    ) {
        return {
            provider: Provider.FRANCHISE,
            city: franchise.address.city,
            state: franchise.address.state,
            stateShort: franchise.address.state,
            country: franchise.address.countryCode,
            latitude: franchise.areaServedLocationData.latitude,
            longitude: franchise.areaServedLocationData.longitude,
            franchiseId: String(franchise.franchiseNumber),
            zip: franchise?.address?.postalCode || '',
        };
    }

    return null;
};

interface UserLocationData {
    provider: Provider
    city: string
    state: string
    stateShort: string
    country: string
    latitude: number
    longitude: number
    zip?: string
    // This should only be used for User Set queries
    franchiseId: string | null
}

interface UserLocationDataSet {
    geolocationApi: UserLocationData | null
    navigator: UserLocationData | null
    userSelectedFranchiseLocation: UserLocationData | null
    userSelectedGeoLocation: UserLocationData | null
    locationChangedByUser: boolean
}

const runIpStackQuery = async (): Promise<UserLocationData | null> => {
    let ipGeo = {};

    try {
        ipGeo = await (await fetch('https://api.ipstack.com/check?access_key=796435b41d113bb2ce49c3598fff5fba')).json();
    } catch (e) {
        return null;
    }

    const {
        latitude, longitude, city, region_code, region_name, country_code,
    } = (ipGeo || {}) as any;

    // only relevant while we decide which location detection provider to use
    if (latitude && longitude) {
        return {
            provider: Provider.IPSTACK,
            latitude,
            longitude,
            city,
            stateShort: region_code,
            country: getCountryISO3(country_code) || country_code,
            state: region_name,
            franchiseId: null,
        };
    }
    return null;
};

// once we choose a location detection provider (and the fallback) will delete the one we don't use
const runNetlifyQuery = async (): Promise<UserLocationData | null> => {
    let netlifyGeo = {};

    try {
        netlifyGeo = await (await fetch(withPrefix('/geo/edge'))).json();
    } catch (e) {
        return runIpStackQuery();
    }

    const { latitude, longitude, city, subdivision, country } = (netlifyGeo || {}) as any;
    // only relevant while we decide which location detection provider to use
    if (latitude && longitude) {
        // below is to overcome the scenario where netlify returns lat and long without a city (which, sometimes happens)
        let reverseGeocodeLocation;
        if (!city || !subdivision?.code || !subdivision?.name) {
            try {
                reverseGeocodeLocation = await (await fetch(
                    withPrefix(`/api/geocode?latitude=${latitude as number}&longitude=${longitude as number}`),
                )).json();
            } catch (e) {
                return null;
            }
        }

        return {
            provider: Provider.NETLIFY,
            latitude,
            longitude,
            city: reverseGeocodeLocation?.city || city,
            stateShort: reverseGeocodeLocation?.stateShort || subdivision?.code,
            country: getCountryISO3(country?.code) || country?.code,
            state: reverseGeocodeLocation?.state || subdivision?.name,
            franchiseId: null,
        };
    }
    return null;
};

// once we choose a location detection provider (and the fallback) will delete the one we don't use
const runNavigatorQuery = async (position: GeolocationPosition): Promise<UserLocationData | null> => {
    const { coords } = position;
    if (!coords || !coords?.latitude || !coords?.longitude) {
        return null;
    }
    try {
        return await (await fetch(
            withPrefix(`/api/geocode?latitude=${coords.latitude}&longitude=${coords.longitude}`),
        )).json();
    } catch (e) {
        return null;
    }
};

interface LocatorProviderReturnable {
    loading: boolean,
    geo: UserLocationData | null,
    nearby: UseNearbyFranchiseMultipleItems,
    franchise: UseNearbyFranchiseSingleItem,
    setFranchise: (
        newFranchise: FranchiseData,
        userLocation: SearchInputAddressComponents | null,
    ) => void,
    geoDerivedFromOverride: boolean, // remove this
    locationChangedByUser: boolean,
    isNationalCallCenter: boolean
    announcements: Announcement[]
}

// Force casting here to make sure context consumers will throw if used outside a provider.
const LocatorContext = React.createContext<LocatorProviderReturnable>(
    null as unknown as LocatorProviderReturnable,
);

// This function should probably be renamed, since it also bundles franchisees and can do overrides
const LocatorProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    /*
    * Get localstorage if it's available,
    * else return null
    * */
    const {
        setData: setNavPromptShown,
        getData: getNavPromptShown,
    } = useLocalStorageState<boolean>(
        'navigator_prompt_shown',
        async (): Promise<boolean> => false,
        [],
        (state: boolean): number => {
            if (state) {
                return 72;
            }
            return 24;
        },
    );

    const {
        data: geoData,
        setData: setGeoData,
        loading: isLoading,
        getData: getSavedGeoBeforeFinishedLoading,
    } = useLocalStorageState<UserLocationDataSet | null>(
        LOCAL_GEO_KEY,
        async (): Promise<UserLocationDataSet> => {
            /*
            * This will run only on initial visit, or
            * again if the localStorage time has expired
            * */
            let locator = await runNetlifyQuery();

            if (!locator) {
                locator = await runIpStackQuery();
            }

            return {
                geolocationApi: locator || null,
                navigator: null,
                userSelectedFranchiseLocation: null,
                userSelectedGeoLocation: null,
                locationChangedByUser: false,
            };
        },
        [],
        (parsedData: UserLocationDataSet | null): number => {
            if (parsedData) {
                if (parsedData.locationChangedByUser) {
                    return 72;
                }
                return 24;
            }
            return 0;
        },
        (parsedData: UserLocationDataSet | null): boolean => !!parsedData?.locationChangedByUser,
    );

    const applyNavigatorQuery = React.useCallback(async (position: GeolocationPosition): Promise<void> => {
        const result = await runNavigatorQuery(position);
        if (result) {
            setGeoData<UserLocationDataSet>({
                geolocationApi: geoData?.geolocationApi || null,
                navigator: { ...result, provider: Provider.NAVIGATOR, franchiseId: null },
                userSelectedFranchiseLocation: geoData?.userSelectedFranchiseLocation || null,
                userSelectedGeoLocation: geoData?.userSelectedGeoLocation || null,
                locationChangedByUser: geoData?.locationChangedByUser || false,
            });
        }
    }, [
        geoData?.locationChangedByUser,
        geoData?.geolocationApi,
        geoData?.userSelectedGeoLocation,
        geoData?.userSelectedFranchiseLocation,
        setGeoData,
    ]);

    React.useEffect(() => {
        // Wait until isLoading is finished - otherwise causes issues on Firefox from all the deps causing re-renders
        if (!isLoading) {
            /*
            * If the user has no geo data, prompt;
            * Or - if the use has geo data, but has not got navigator geo data
            * (and has not manually selected a franchise), prompt again
            * */

            const geoDataToUse = geoData || getSavedGeoBeforeFinishedLoading();
            const navPromptShown = getNavPromptShown();
            if (!geoDataToUse || (!geoDataToUse?.navigator && !navPromptShown)) {
                setNavPromptShown(true);
                navigator.geolocation.getCurrentPosition(
                    applyNavigatorQuery,
                    (error) => {
                        // if it fails, we add an event to the dataLayer
                        if ('dataLayer' in window && Array.isArray(window.dataLayer) && !window.dataLayer.find(item => item.event === 'LocationPermission' && !item.allowed)) {
                            window.dataLayer.push({
                                event: 'LocationPermission',
                                allowed: false,
                                error: error.message,
                            });
                        }
                    },
                    {
                        enableHighAccuracy: true,
                    },
                );
            }
        }
    }, [
        applyNavigatorQuery,
        geoData,
        getNavPromptShown,
        getSavedGeoBeforeFinishedLoading,
        isLoading,
        setNavPromptShown,
    ]);

    // Get the most appropriate geo provider result
    const geoToUse = (() => {
        if (geoData?.locationChangedByUser && geoData?.userSelectedFranchiseLocation) {
            return geoData.userSelectedFranchiseLocation;
        }
        if (geoData?.navigator) {
            return geoData.navigator;
        }
        if (geoData?.geolocationApi) {
            return geoData?.geolocationApi;
        }
        return null;
    })();

    // Get the lat+lng from the most appropriate geo provider
    const nearbyQuery = geoToUse ? {
        latitude: Number(geoToUse.latitude),
        longitude: Number(geoToUse.longitude),
        franchiseId: geoToUse.franchiseId || null,
        provider: geoToUse.provider || null,
        country: geoToUse.country || null,
        locationType: null,
        zip: null,
    } : null;

    // Find franchisees based on the lat+lng available

    const {
        isLoading: isLoadingFranchises,
        data: nearbyFranchises,
        announcements,
    } = useNearbyFranchises(nearbyQuery);

    // User is choosing a different location to the ones selected for them,
    // Create their Geo data from the Yext of the franchise
    // Even though we type-check the Yext existing, it should always exist,
    // As we do not build any franchises or franchise json if the Yext is missing
    const setFranchiseExternal = React.useCallback((
        newFranchise: FranchiseData,
        userLocation: SearchInputAddressComponents | null,
    ): void => {
        if (newFranchise && newFranchise.franchiseNumber) {
            const userSelectionData = franchiseToGeo(newFranchise);

            if (userSelectionData) {
                const userSelectedGeoLocation: UserLocationData = {
                    city: userLocation?.city ?? userSelectionData.city,
                    country: userLocation?.country ?? userSelectionData.country,
                    franchiseId: null,
                    latitude: userLocation?.latitude ?? userSelectionData.latitude,
                    longitude: userLocation?.longitude ?? userSelectionData.longitude,
                    provider: Provider.FRANCHISE,
                    state: userLocation?.state ?? userSelectionData.state,
                    stateShort: userLocation?.stateShort ?? userSelectionData.stateShort,
                    zip: userLocation?.zip ?? userSelectionData.zip,
                };

                const data: UserLocationDataSet = {
                    geolocationApi: geoData?.geolocationApi || null,
                    navigator: geoData?.navigator || null,
                    userSelectedFranchiseLocation: userSelectionData,
                    userSelectedGeoLocation,
                    locationChangedByUser: true,
                };

                setGeoData<UserLocationDataSet>(data);
            }
        }
    }, [geoData, setGeoData]);

    const value = React.useMemo(() => {
        const locatorData: LocatorProviderReturnable = {
            loading: (() => {
                if (!geoToUse) {
                    return true;
                }
                return (isLoading || isLoadingFranchises);
            })(),
            geo: geoToUse || null,
            nearby: nearbyFranchises,
            franchise: nearbyFranchises[0] || ({
                error: null,
                isLoading: isLoading || isLoadingFranchises,
                data: {
                    id: 'NATIONAL_CALL_CENTER', // This may get forwarded to Invoca?
                    name: 'National Call Center',
                    mainPhone: PHONE_NUMBER,
                },
            } as UseNearbyFranchiseSingleItem),
            setFranchise: setFranchiseExternal,
            geoDerivedFromOverride: false, // remove this
            locationChangedByUser: geoData?.locationChangedByUser || false,
            isNationalCallCenter: !nearbyFranchises[0],
            announcements: announcements || [],
        };
        return locatorData;
    }, [
        geoData?.locationChangedByUser,
        geoToUse,
        isLoading,
        isLoadingFranchises,
        nearbyFranchises,
        setFranchiseExternal,
        announcements,
    ]);

    return (
        <LocatorContext.Provider value={value}>
            {children}
        </LocatorContext.Provider>
    );
};

export default LocatorProvider;

export const useLocator = (): LocatorProviderReturnable => {
    const data = React.useContext(LocatorContext);
    if (!data) {
        throw new Error('useLocator used outside of context');
    }
    return data;
};
