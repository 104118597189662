import React from 'react';
import { PolymorphicWithoutRef } from '../polymorphic';

export const colors = {

    primaryOrange: 'var(--color-primary-orange)',
    primaryOrangeHover: 'var(--color-primary-orange-hover)',
    primaryGreen: 'var(--color-primary-green)',
    secondaryGreen: 'var(--color-secondary-green)',

    trueBlack: '#000',
    shadesBlack: 'var(--color-shades-black)',
    shadesWhite: 'var(--color-shades-white)',
    shadesParchment: 'var(--color-shades-parchment)',

    statesError: 'var(--color-states-error)',
    statesSuccess: 'var(--color-states-success)',
    statesFocus: 'var(--color-states-focus)',
    statesWarning: 'var(--color-states-warning)',

    grey50: 'var(--color-grey-50)',
    grey100: 'var(--color-grey-100)',
    grey200: 'var(--color-grey-200)',
    grey300: 'var(--color-grey-300)',
    grey400: 'var(--color-grey-400)',
    grey500: 'var(--color-grey-500)',
    grey600: 'var(--color-grey-600)',
    grey700: 'var(--color-grey-700)',
    grey800: 'var(--color-grey-800)',
    grey900: 'var(--color-grey-900)',

};

export type ColorProps = {
    color?: keyof typeof colors,
    backgroundColor?: keyof typeof colors,
};
const Color = <C extends React.ElementType = 'div'>({
    color,
    backgroundColor,
    as,
    ...props
}: PolymorphicWithoutRef<C, ColorProps>): JSX.Element => {
    const Component = as || 'div';
    return (
        <Component
            {...props}
            css={{
                color: (() => {
                    if (color && colors[color]) {
                        return colors[color];
                    }
                    return undefined;
                })(),
                backgroundColor: (() => {
                    if (backgroundColor && colors[backgroundColor]) {
                        return colors[backgroundColor];
                    }
                    return undefined;
                })(),
            }}
        />
    );
};
export default Color;
